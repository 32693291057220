import React, {useEffect} from 'react';
import {Modal, Table, Tag} from 'antd';

const columns = [

    {
        title: "De",
        dataIndex: "from",
        key: "from",
    },
    {
        title: "À",
        dataIndex: "to",
        key: "to",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
    {
        title: "Durée",
        dataIndex: "duration",
        key: "duration",
    },
    {
        title: "Statut",
        dataIndex: "callStatus",
        key: "callStatus",
        render: tag => {
            return tag === "completed" ? (
                <Tag color={'green'} key={tag}>
                    {tag==="completed"?"Terminés":null}
                </Tag>
            ) : (<Tag color={'volcano'} key={tag}>
                {tag==="busy"?"Occupé":tag==="unavailable"?"indisponible":tag==="ringing"?"Sonne":tag==="no-answer"?"Manqués":tag==="in-progress"?"En-Cours":null}
            </Tag>)
        },
    },
    {
        title: "Prix par seconde",
        dataIndex: "price_per_seconde",
        key: "price_per_seconde",
    },
    {
        title: "Prix totale",
        dataIndex: "total_price",
        key: "total_price"
    }


];
const ProCallHistory = ({data, visible, openModal}) => {
    useEffect(() => {

    }, [data, visible, openModal]);

    return (
        <div>
            <Modal
                title="Historique d'appels"
                centered
                visible={visible}
                onCancel={openModal}
                footer={[null]}
                width={1000}
                height={500}
            >
                {data[0] && <Table columns={columns} dataSource={data[0].map((c, index) => {
                    return {
                        key: index,
                        from: c.from_first_name + ' ' + c.from_last_name,
                        to: c.to_first_name + ' ' + c.to_last_name,
                        date: c.date_call || '-',
                        duration: c.duration || '-',
                        price_per_seconde: c.price_per_second,
                        total_price: c.price_total || '-',
                        callStatus: c.callStatus
                    }
                })}/>}
            </Modal>
        </div>
    );
};

export default ProCallHistory;
