import React from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Switch } from "antd";

const { TextArea } = Input;

const AddCategory = ({ openModal, visible, AddCategory }) => {
    const [form] = Form.useForm();



    const onFinish = (values) => {
        values.active = true;
        AddCategory(values);
        form.resetFields();
        openModal();
    };

    const onFinishFailed = (errorInfo) => {
    };
    return (
        <div>
            <Modal
                visible={visible}
                title="Ajouter une nouvelle catégorie"
                onCancel={() => { openModal(); form.resetFields(); }}
                footer={null}
            >
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="category_name"
                        label="Nom"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer le titre de catégorie!',
                            },
                        ]}
                    >
                        <Input placeholder={'Titre'} />
                    </Form.Item>

                    <Form.Item
                        name="category_description"
                        label="Description"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer la description!',
                            },
                        ]}
                    >
                        <TextArea rows={2} placeholder={'Description...'} />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name="has_speciality" label={'Sous catégorie'}>
                                <Switch checkedChildren="has speciality" unCheckedChildren="No speciality"
                                    defaultChecked={false} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="have_card" label={'Carte Pro'}>
                                <Switch checkedChildren="have card" unCheckedChildren="No Card"
                                    defaultChecked={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="annuel_sub_price"
                        label="Prix d'inscription annuel TND"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer le prix d\'inscription annuel!',
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder={'Prix d\'inscription annuel /TND'}
                            style={{
                                width: '100%',
                            }}
                            type={'number'}
                            step={0.1}
                            min="0"
                        />
                    </Form.Item>
                    <Form.Item
                        name="price_per_sec"
                        label="Prix d'appel par seconde TND"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer le prix d\'appel par seconde!',
                            },
                        ]}
                    >
                        <Input
                            placeHolder={'Prix d\'appel par seconde /TND'}
                            style={{
                                width: '100%',
                            }}
                            type={'number'}
                            step={0.001}
                            min="0"
                        />
                    </Form.Item>
                    <Form.Item
                        name="istishara_commision"
                        label="ISTISHARA commission"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer la commission de ISTISHARA !',
                            },
                        ]}
                    >

                        <InputNumber
                            placeholder={'ISTISHARA commission %'}
                            style={{
                                width: '100%',
                            }}
                            min="0"
                            step={'0.1'}
                            type={'number'}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" block htmlType="submit">
                            Valider
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AddCategory;
