import React, {useEffect, useState} from 'react';
import ClientService from "../Services/ClientService";
import ClientTable from "../Containers/client/ClientTable";
import {Button, Input, message, Pagination, Row} from "antd";
import AddClient from "../Containers/client/AddClient";
import UpdateClient from "../Containers/client/UpdateClient";

const {Search} = Input;
let params = {limit: 10, page: 1};
const ClientComponent = () => {

    const [clients, setClients] = useState([]);
    const [totalItems,setTotalItems] = useState(null);
    const [openAddClientModal, setOpenAddClientModal] = useState(false);
    const [openUpdateClientModal, setOpenUpdateClientModal] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    const handle_openAddclientModal = () => {
        setOpenAddClientModal(!openAddClientModal);
    };
    const handle_openUpdateclientModal = () => {
        setOpenUpdateClientModal(!openUpdateClientModal);
    };
    const fetch_clients = () => {
        ClientService.get_all_clients(params).then((response => {setClients(response.clients); setTotalItems(response.countTotal)}))
            .catch((e)=> console.log(e.message))
    };
    useEffect(() => {
        params = {limit: 10, page: 1};
        fetch_clients()
    }, []);

    const handleUpdate_client = (e) => {
        ClientService.update_client(selectedClient.user_id,e)
            .then(()=>message.success('Client mis à jour avec succès '))
            .then(()=>fetch_clients()).catch(()=>message.warn('l\'opération mise à jour a echoué  '))
    };

    const handlesearch = () => {
        params.page=1;
        fetch_clients();
    };
    const handleSelectedClient = (e) => {
        setSelectedClient(e);
        handle_openUpdateclientModal();
    };
    const handle_add_client = (e) => {
        ClientService.create_client(e).then(() => message.success('Client créé avec succès'))
            .then(() => fetch_clients());
    };
    const exportCSV = () => {
        ClientService.export_virement_csv().then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'file.csv'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
    };
    return (
        <div>
            <Search style={{width: '30%'}} placeholder="Rechercher client"
                    onChange={(e) => e.target.value !== '' ? params.search = e.target.value : delete params.search}
                    onSearch={(e) => {
                        handlesearch();
                    }} enterButton/>
        
                <Button type={'primary'} onClick={() => exportCSV()}>Exporter en CSV</Button>
        
            <Row justify={'end'} gutter={[0, 16]}>
                <Button type={'primary'} onClick={handle_openAddclientModal}>Ajouter un client</Button>

            </Row>
            <br/>
            <ClientTable data={clients} selectClient={handleSelectedClient}/>
            <Row style={{paddingTop:10}} justify={'end'}>
                <Pagination defaultCurrent={1}
                            total={totalItems}
                            showSizeChanger
                            current={params.page || 1}
                            pageSizeOptions={[10, 20, 50, 100]}
                            onShowSizeChange={(e,v)=>{params.limit=v;fetch_clients()}}
                            onChange={(e,v)=>{params.page=e;fetch_clients()}}/>
            </Row>
            <AddClient openModal={handle_openAddclientModal} visible={openAddClientModal}
                       addclient={handle_add_client}/>
            {selectedClient&&<UpdateClient openModal={handle_openUpdateclientModal}
                          visible={openUpdateClientModal}
                          client={selectedClient}
                          updateclient={handleUpdate_client}/>}
        </div>
    );
};

export default ClientComponent;
