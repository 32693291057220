import axios from 'axios';

class AuthService {
    async login(login, password) {
        return await axios.post(`${process.env.REACT_APP_API_URL}backoffice/login`, {
            login: login,
            password: password
        }).then((response) => {
            localStorage.setItem('accessToken', response.data.data.token);
            return response.data;

        }).catch(error => {
            return error.response
        });


    }
}
export default new AuthService();
