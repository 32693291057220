import axios from 'axios';

class ClientService {

    async get_all_clients(params){
        return await axios.get(`${process.env.REACT_APP_API_URL}backoffice/filter_client?type=client`,{params})
            .then((response) => {
            return response.data.data;
        }).catch(error => {
            return error.response
        });
    }


    async create_client(client){
        return await axios.post(`${process.env.REACT_APP_API_URL}users/create`,client)
            .then(({data})=>{
                return data.data
            }).catch(error => {
                return error.response
            });
    }

    async update_client(id,client){
        return await axios.put(`${process.env.REACT_APP_API_URL}users/update/${id}`,client)
            .then(({data})=>{
                return data.data
            }).catch(error => {
                return error.response
            });
    }
    async export_virement_csv() {
        return await axios.get(`${process.env.REACT_APP_API_URL}backoffice/export_clients`)
            .then(({ data }) => {
                return data;
            }).catch(error => {
                return error.response
            });
    }

}
export default new ClientService();
