import React, { useEffect } from 'react';
import { Table, Tag } from 'antd';
const generateDate = time =>
    new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        time.split(":")[0],
        time.split(":")[1],
        0
    );
const res = UTCString => {
    const result = new Date(
        UTCString.setMinutes(UTCString.getMinutes() + (2 * 60))
    );
    return result
}
const columns = [

    {
        title: "De",
        dataIndex: "from",
        key: "from",
    },
    {
        title: "À",
        dataIndex: "to",
        key: "to",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: date => {
            let msg
            if (date) {
                const Date = date.split('T')[0]
                const Time = date.split('T')[1].split('.')[0].substring(0, 5)
                const UTCString = generateDate(Time);
                const readbleString = res(UTCString).toTimeString().split(" ")[0];
                msg = `le : ${Date} à: ${readbleString.substr(0, readbleString.length - 3)}`
            }
            console.log('updatedAt', date)
            return (
                <Tag color="blue" key={date}>
                    {msg}
                </Tag>
            )
        },
    },
    {
        title: "Durée",
        dataIndex: "duration",
        key: "duration",
    },
    {
        title: "Statut",
        dataIndex: "callStatus",
        key: "callStatus",
        render: tag => {
            return tag === "completed" ? (
                <Tag color={'green'} key={tag}>
                    {tag === "completed" ? "Terminés" : null}
                </Tag>
            ) : (<Tag color={'volcano'} key={tag}>
                {tag === "busy" ? "Occupé" : tag === "unavailable" ? "indisponible" : tag === "ringing" ? "Sonne" : tag === "no-answer" ? "Manqués" : tag === "in-progress" ? "En-Cours" : null}
            </Tag>)
        },
    },
    {
        title: "Prix par seconde",
        dataIndex: "price_per_seconde",
        key: "price_per_seconde",
    },
    {
        title: "Prix totale ",
        dataIndex: "total_price",
        key: "total_price"
    }


];
const AllCallHistoryTable = ({ data }) => {
    useEffect(() => {

    }, [data]);

    return data && (
        <div>
            <Table columns={columns} pagination={false} dataSource={data.map((c, index) => {
                return {
                    key: index,
                    from: c['From_user.first_name'] + ' ' + c['From_user.last_name'],
                    to: c['To_user.first_name'] + ' ' + c['To_user.last_name'],
                    date: c.date_call || '-',
                    duration: c.duration || '-',
                    price_per_seconde: c.price_per_second,
                    total_price: c.price_total || '-',
                    callStatus: c.callStatus
                }
            })} />
        </div>
    );
};

export default AllCallHistoryTable;
