import React, { useEffect, useState } from 'react';
import CallService from "../Services/CallService";
import AllCallHistoryTable from "../Containers/callHistory/AllCallHistoryTable";
import { Button, Card, Col, DatePicker, Input, Pagination, Row, Select } from "antd";
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;
let params = { callStatus: 'completed', search_from: '', search_to: '' };


function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const dateFormat = 'YYYY-MM-DD';
const CallHistoryComponent = () => {

    const [callHistory, setCallHistory] = useState([]);
    const [totalItems, setTotalItems] = useState(null);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    
    useEffect(() => {
        params = { callStatus: 'completed', search_from: '', search_to: '', page: 1 };
        setTo('');
        setFrom('');
        let d = new Date();
        // Set it to one month ago
        d.setMonth(d.getMonth() - 1);
        params.start_date = formatDate(d);
        params.end_date = formatDate(new Date());
        fetch_call_history();
    }, []);

    const fetch_call_history = () => {
        CallService.get_call_history(params)
            .then((response) => {
                setCallHistory(response.callHistory);
                setTotalItems(response.countTotal)
            }).catch((e) => console.log(e.message));

    };
    const clearFilter = () => {
        params = { callStatus: 'completed', search_from: '', search_to: '', page: 1 };
        setTo('');
        setFrom('');
        let d = new Date();
        // Set it to one month ago
        d.setMonth(d.getMonth() - 1);
        params.start_date = formatDate(d);
        params.end_date = formatDate(new Date());
        delete params.search_from;
        delete params.search_to;
        setFrom('');
        setTo('');
        fetch_call_history();
    };
    return (
        <div>
            <Card title={'Filtre'}>
                <Row gutter={[12, 12]} >
                    <Col >
                        Date : <RangePicker allowClear={false}
                            value={[moment(params.start_date, dateFormat), moment(params.end_date, dateFormat)]}
                            format={dateFormat}
                            onChange={(m, dateString) => {
                                params.start_date = dateString[0];
                                params.end_date = dateString[1];
                                params.page = 1;
                                fetch_call_history()
                            }}
                            disabledDate={current => {
                                return current && current > moment()
                            }}
                        />
                    </Col>
                    <Col >
                        Statut: <Select
                            placeholder="Status..."
                            defaultValue={'completed'}
                            value={params.callStatus}
                            onSelect={(e) => { params.callStatus = e; params.page = 1; fetch_call_history() }}
                        >
                            <Option value={'completed'}>Terminé</Option>
                            <Option value={'busy'}>Occupé</Option>
                            <Option value={'unavailable'}>Indisponible</Option>
                            <Option value={'ringing'}>Sonne</Option>
                            <Option value={'no-answer'}>Manqués</Option>
                            <Option value={'in-progress'}>En-Cours</Option>

                        </Select>
                    </Col>
                    <Col >
                        <Input.Group compact>
                            <Input style={{ width: '35%' }} value={from} placeholder={'De...'} onChange={(e) => { params.search_from = e.target.value; setFrom(e.target.value) }} />
                            <Input style={{ width: '35%' }} value={to} placeholder={'À...'} onChange={(e) => { params.search_to = e.target.value; setTo(e.target.value) }} />
                            <Button type={'primary'} onClick={() => { params.page = 1; fetch_call_history() }}>Rechercher</Button>
                        </Input.Group>
                    </Col>

                </Row>
                <Row justify={'end'} style={{ marginTop: 20 }}>
                    <Button onClick={() => clearFilter()}>Réinitialiser filtre</Button>
                </Row>
            </Card>
            <AllCallHistoryTable data={callHistory} />
            <Row style={{ paddingTop: 10 }} justify={'end'}>
                <Pagination defaultCurrent={1}
                    total={totalItems}
                    showSizeChanger
                    current={params.page}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onShowSizeChange={(e, v) => { params.limit = v; fetch_call_history() }}
                    onChange={(e, v) => { params.page = e; fetch_call_history() }} />
            </Row>
        </div>
    );
};

export default CallHistoryComponent;
