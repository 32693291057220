import React, {useState,useEffect} from 'react';
import {Input} from "antd";

const NumericInput = ({maxLength, placeholder, style,value,onChange}) => {
    const [number, setNumber] = useState(value);
    const onNumberChange = e => {
        const v = e.target.value;
        const reg = /^[0-9]*$/;
        if (v.toString().length<=maxLength) {
            setNumber(v);
            onChange(v)
        }
    };
    useEffect(()=>{
        if(value)
        {
        setNumber(value)}
    },[value,maxLength,placeholder,onNumberChange]);
    return (
        <Input
            style={style}
            value={number}
            onChange={onNumberChange}
            placeholder={placeholder}
            type={'number'}

        />
    );

};

export default NumericInput;
