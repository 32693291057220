import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import '../Helpers/axiosInterceptor';
import AdminLayout from "./AdminLayout";
import CategoriesComponent from "./CategoriesComponent";
import ProfessionnelComponent from "./ProfessionnelComponent";
import ClientComponent from "./ClientComponent";
import CallHistoryComponent from "./CallHistoryComponent";
import TransactionsHistoryComponent from "./TransactionsHistoryComponent";
import DashBoardComponent from "./DashBoardComponent";

const AdminRouter = ({match}) => {
    return (
        <AdminLayout>
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`}/>
                <Route path={`${match.url}/dashboard`} component={DashBoardComponent}/>
                <Route path={`${match.url}/categories`} component={CategoriesComponent}/>
                <Route path={`${match.url}/professionnels`} component={ProfessionnelComponent}/>
                <Route path={`${match.url}/clients`} component={ClientComponent}/>
                <Route path={`${match.url}/CallHistory`} component={CallHistoryComponent}/>
                <Route path={`${match.url}/TransactionsHistory`} component={TransactionsHistoryComponent}/>
                <Redirect to="/error"/>
            </Switch>
        </AdminLayout>
    );
};

export default AdminRouter;
