import React from 'react';
import AuthService from "../Services/AuthService";
import {Button, Card, Col, Form, Input, Row} from 'antd';

const LoginComponent = () => {

    const onFinish = ({login, password}) => {
        AuthService.login(login, password).then(() => {
            window.location.href = "/";
        });
    };

    const onFinishFailed = (errorInfo) => {
    };
    return (
        <Row justify={'center'} align={'middle'} className={'login_body'}>
            <Card className={'login_card'}>
                <Row justify={'center'} align={'middle'}>
                    <Col className={'login_card_title'}>
                        Istishara back office
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                            <Form
                                name="basic"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row>
                                    <Col span={24}>
                                    <Form.Item
                                        name="login"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your login!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder={'Identifiant...'}/>
                                    </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password placeholder={'Password...'}/>
                                    </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" block htmlType="submit">
                                            Login
                                        </Button>
                                    </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Row>
    );
};

export default LoginComponent;
