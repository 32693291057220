import React, { useEffect } from 'react';
import { Table, Tag } from "antd";

const generateDate = time =>
    new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        time.split(":")[0],
        time.split(":")[1],
        0
    );
const res = UTCString => {
    const result = new Date(
        UTCString.setMinutes(UTCString.getMinutes() + (2 * 60))
    );
    return result
}

const columns = [
    {
        title: "transaction_from",
        dataIndex: "transaction_from",
        key: "transaction_from",
    },
    {
        title: "transaction_to",
        dataIndex: "transaction_to",
        key: "transaction_to",
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: date => {
            let msg
            if (date) {
                const Date = date.split('T')[0]
                const Time = date.split('T')[1].split('.')[0].substring(0, 5)
                const UTCString = generateDate(Time);
                const readbleString = res(UTCString).toTimeString().split(" ")[0];
                msg = `le : ${Date} à: ${readbleString.substr(0, readbleString.length - 3)}`
            }
            return (
                <Tag color="blue" key={date}>
                    {msg}
                </Tag>
            )
        },
    },
    {
        title: "Montant",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "transaction_type",
        dataIndex: "transaction_type",
        key: "transaction_type",
    },
    {
        title: "Statut",
        dataIndex: "status",
        key: "status",
        render: tag => {
            return tag === "completed" ? (
                <Tag color={'green'} key={tag}>
                    {tag === "completed" ? "Terminés" : null}
                </Tag>
            ) : (<Tag color={'volcano'} key={tag}>
                {tag.toUpperCase()}
            </Tag>)
        },
    }
];
const AllVirementHistoryTable = ({ data }) => {
    useEffect(() => {

    }, [data]);
    return (
        <div>

            {data && <Table pagination={false} columns={columns} dataSource={data.map((c, index) => {
                return {
                    key: index,
                    date: c.date || '-',
                    status: c.status,
                    amount: c.amount || '-',
                    transaction_type: c.transaction_type || '-',
                    transaction_from: c.transaction_from,
                    transaction_to: c.transaction_to
                }
            })} />}
        </div>
    );
};

export default AllVirementHistoryTable;
