import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Modal, Row, Select, Switch, TreeSelect} from "antd";
import CategoryService from "../../Services/CategoryService";
import PicturesWall from "./PicturesWall";
import ProfessionnelService from "../../Services/ProfessionnelService";
import {findPhoneNumbersInText} from 'libphonenumber-js'
import NumericInput from "../../Helpers/NumericInput";

const Tunisia_states = ['Tunis',
    'Sfax',
    'Sousse',
    'Gabès',
    'Kairouan',
    'Bizerte',
    'Gafsa',
    'Nabeul',
    'Ariana',
    'Kasserine',
    'Monastir',
    'Tataouine',
    'Medenine',
    'Béja',
    'Jendouba',
    'El_Kef',
    'Mahdia',
    'Sidi_Bouzid',
    'Tozeur',
    'Siliana',
    'Kebili',
    'Zaghouan',
    'Ben_Arous',
    'Manouba'
];
const {TextArea} = Input;
const {Option} = Select;

function validatePhoneNumber(code, number, cb) {
    let phoneNumber = code + number;
    let phone = phoneNumber && findPhoneNumbersInText(phoneNumber);
    if (!phone[0])
        cb('Votre numéro de téléphone est invalide !');

    cb()

}

const UpdateProfessionnel = ({visible, openModal, pro, updatePro}) => {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [approuved, setApprouved] = useState(null);
    const [active, setActive] = useState(null);
    const fetch_categories = () => {
        CategoryService.get_all_categories().then(({data}) => setCategories(data))
    };

    useEffect(() => {
        pro.state_account = pro.state_account === 'null' ? pro.state_account.toString() : pro.state_account;
        pro.phonecode = '+216';
        let phone = pro.phone && findPhoneNumbersInText(pro.phone);
        if (phone[0]) {
            pro.phone = phone[0].number.nationalNumber;
        }
        form.setFieldsValue(pro);
        setActive(pro.active);
        fetch_categories();
    }, [pro, form]);

    const notifyPro = () => {
        ProfessionnelService.notify_pro(pro.user_id, {
            pro_first_name: pro.first_name,
            pro_last_name: pro.last_name,
            pro_email: pro.email
        })
    };
    const onFinish = (values) => {
        values.active = values.active.toString();
        values.phone = values.phonecode + values.phone;
        delete values.phonecode;
        updatePro(pro.user_id, values);
        form.resetFields();
        openModal();
    };

    const onFinishFailed = (errorInfo) => {
    };
    const onCancel = () => {
        openModal();
        setApprouved(null);
        form.resetFields();
    };
    return pro && (
        <div>
            <Modal
                visible={visible}
                title="Mise à jour de professionnel "
                onCancel={onCancel}
                footer={null}
                width={1000}
            >
                <Form
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="first_name"
                                label={"Nom"}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez insérer votre nom !!',
                                    },
                                ]}
                            >
                                <Input placeholder={'Nom'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Prénom"}
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez insérer votre prénom !',
                                    },
                                ]}
                            >
                                <Input placeholder={'Prénom'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label={"Email"}
                                name="email"
                            >
                                <Input placeholder={'Email'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={'Téléphone'}>
                                <Input.Group compact>
                                    <Form.Item
                                        name={'phonecode'}
                                    >
                                        <Select defaultValue={'+216'} style={{width: 100}}>
                                            <Option key={1} value={'+216'}>{'+216'}</Option>)
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name={'phone'}
                                        noStyle
                                        rules={[{required: true, message: 'Numéro de téléphone est obligatoire'},
                                            {
                                                validator: (rule, value, cb) => {
                                                    validatePhoneNumber(form.getFieldValue('phonecode'), value, cb)
                                                }
                                            }
                                        ]}
                                    >
                                        <NumericInput style={{width:'50%'}} maxLength={8} placeholder={'Numéro de téléphone'}/>
                                    </Form.Item>
                                </Input.Group>

                            </Form.Item>

                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label={"Région"}
                                name="region"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez choisir votre région !',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Région..."
                                    allowClear
                                >
                                    {Tunisia_states && Tunisia_states.map((ts, index) => {
                                        return (
                                            <Option key={index} value={ts}>
                                                {ts}
                                            </Option>)
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Catégorie"}
                                name="category_job_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez choisir un catégorie!',
                                    },
                                ]}
                            >
                                <TreeSelect
                                    placeholder="Catégorie..."
                                    onSelect={(e) => {
                                        setSelectedCategory(categories.find(x => x.category_job_id === e));
                                    }}
                                    treeData={
                                        categories && categories.map((c, index) => {
                                            return ({
                                                    title: c.category_name,
                                                    value: c.category_job_id,
                                                    children: c.speciality.length > 0 && c.speciality.map(sc => {
                                                        return (
                                                            {
                                                                title: sc.category_name,
                                                                value: sc.category_job_id,
                                                            }
                                                        )
                                                    })

                                                }

                                            )
                                        })}


                                />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.Item
                        label={"Description"}
                        name="description"
                    
                    >
                        <TextArea rows={2} placeholder={'Description...'}/>
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Form.Item
                                label={"CIN"}
                                name="num_cin"
                                
                            >
                                <NumericInput style={{width:'100%'}} maxLength={8} placeholder={'CIN ...'}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="active" label={'Active'}>
                                <Switch checkedChildren="Active" unCheckedChildren="Blocked"
                                        onChange={(e) => setActive(e.toString())}
                                        checked={active === 'true'}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="state_account" label={'Statut du compte'}>
                                <Select
                                    placeholder="Statut du compte..."
                                    allowClear
                                    onSelect={(e) => setApprouved(e)}
                                >
                                    <Option key={1} value={'approuved'}>Approuvé</Option>
                                    <Option key={2} value={'not-approuved'}>Rejeté</Option>
                                    <Option key={3} value={null}>En attente</Option>
                                    <Option key={4} value={'in-progress'}>En cours</Option>

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>


                    {pro.num_passport && <Form.Item
                        label={"Passport"}
                        name="num_passport"
                        
                    >
                        <NumericInput style={{width:'100%'}} maxLength={999} placeholder={'Passport ...'}/>
                    </Form.Item>}


                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label={"Bank"}
                                name="bank_name"
                               
                            >
                                <Input placeholder={'Bank ...'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Agence bancaire"}
                                name="bank_agency"
                              
                            >
                                <Input placeholder={'Veuillez insérer le nom de votre agence bancaire !'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item
                                label={"RIB"}
                                name="num_rib"
                               
                            >
                                <NumericInput style={{width:'100%'}} maxLength={20} placeholder={'RIB...'}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={"Durée /seconde"}
                                name="min_call_allowed_per_sec"
                              
                            >
                                <InputNumber type={'number'} min={0} style={{width: '100%'}}
                                             placeholder={'Durée minimale d\'appel autorisée par seconde'}/>
                            </Form.Item>
                        </Col>
                    </Row>


                    {((selectedCategory && selectedCategory.have_card) || pro.num_pro_card) && <Form.Item
                        label={"Carte Pro"}
                        name="num_pro_card"
                        
                    >
                        <Input style={{width: '100%'}} placeholder={'Numéro de carte pro ...'}/>
                    </Form.Item>}


                    {pro.documents &&
                    <PicturesWall userId={pro.user_id} mtoken={pro.m_token} files={pro.documents.map((x, index) => {
                        return ({
                            uid: x,
                            name: 'photo '+index,
                            status: 'done',
                            url: `https://istishara-api.digit-dev.com/resources/efiles/public/upload/${x.file_name}`
                        })
                    })}/>
                    }

                    <Form.Item>
                        <Button type="primary" block htmlType="submit">
                            Valider
                        </Button>
                    </Form.Item>
                    {approuved === 'approuved' && <Form.Item>
                        <Button type="secondary" block onClick={() => notifyPro()}>
                            Notifier PRO
                        </Button>
                    </Form.Item>}

                </Form>

            </Modal>
        </div>
    );
};

export default UpdateProfessionnel;
