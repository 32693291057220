import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, Radio, Row, Select, TreeSelect } from "antd";
import CategoryService from "../../Services/CategoryService";
import { findPhoneNumbersInText } from "libphonenumber-js";
import NumericInput from "../../Helpers/NumericInput";

const { TextArea } = Input;
const { Option } = Select;
const Tunisia_states = ['Tunis',
    'Sfax',
    'Sousse',
    'Gabès',
    'Kairouan',
    'Bizerte',
    'Gafsa',
    'Nabeul',
    'Ariana',
    'Kasserine',
    'Monastir',
    'Tataouine',
    'Medenine',
    'Béja',
    'Jendouba',
    'El_Kef',
    'Mahdia',
    'Sidi_Bouzid',
    'Tozeur',
    'Siliana',
    'Kebili',
    'Zaghouan',
    'Ben_Arous',
    'Manouba'
];

function validatePhoneNumber(number, cb) {
    let phoneNumber = '+216' + number;
    let phone = phoneNumber && findPhoneNumbersInText(phoneNumber);
    if (!phone[0])
        cb('Votre numéro de téléphone est invalide!');

    cb()

}

const AddProfessionnel = ({ visible, openModal, addPro }) => {

    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [phone, setPhone] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [verificationDocument, setVerificationDocument] = useState('cin');

    const fetch_categories = () => {
        CategoryService.get_all_categories().then(({ data }) => setCategories(data))
    };
    useEffect(() => {
        fetch_categories();
    }, []);
    const onFinish = (values) => {
        values.type = 'professionel';
        values.active = true;
        values.finished_signup = true;
        values.phone = '+216' + values.phone;
        values.state_payment_signup = "payed";
        addPro(values);
        form.resetFields();
        openModal();
    };


    const onFinishFailed = (errorInfo) => {
    };

    return (
        <div>
            <Modal
                visible={visible}
                title="Ajouter un professionnel"
                onCancel={() => {
                    openModal();
                    form.resetFields()
                }}
                footer={null}
            >
                <Form
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Nom"
                                name="first_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez insérer votre nom !',
                                    },
                                ]}
                            >
                                <Input placeholder={'Nom'} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Prénom"
                                name="last_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez insérer votre prénom!',
                                    },
                                ]}
                            >
                                <Input placeholder={'Prénom'} />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.Item
                        label="Email"
                        name="email"
                    >
                        <Input placeholder={'Email'} />
                    </Form.Item>
                    <Form.Item
                    >
                        <Input.Group >
                            <Row  >
                                <Col >
                                    <Form.Item
                                        label="Phone"
                                        name={'phonecode'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez choisir le code de votre pays !',
                                            },
                                        ]}
                                    >
                                        <Select defaultValue={'+216'} style={{ width: 100 }}>
                                            <Option key={1} value={'+216'}>{'+216'}</Option>)
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col >
                                    <Form.Item

                                        name={'phone'}
                                        noStyle
                                        rules={[{ required: true, pattern: new RegExp(/\d*/), message: 'Numéro de téléphone est obligatoire' },
                                        {
                                            validator: (rule, value, cb) => {
                                                validatePhoneNumber(value, cb)
                                            }
                                        }
                                        ]}
                                    >
                                        <NumericInput style={{ width: '79%' }} maxLength={8} placeholder={'Numéro de téléphone'} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Input.Group>

                    </Form.Item>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Region"
                                name="region"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez choisir votre région!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Région..."
                                    allowClear
                                >
                                    {Tunisia_states && Tunisia_states.map((ts, index) => {
                                        return (
                                            <Option key={index} value={ts}>
                                                {ts}
                                            </Option>)
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Catégorie"
                                name="category_job_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez choisir une catégorie!',
                                    },
                                ]}
                            >
                                <TreeSelect
                                    placeholder="Catégorie..."
                                    onSelect={(e) => {
                                        setSelectedCategory(categories.find(x => x.category_job_id === e));
                                    }}
                                    treeData={
                                        categories && categories.map((c, index) => {
                                            return ({
                                                title: c.category_name,
                                                value: c.category_job_id,
                                                children: c.speciality.length > 0 && c.speciality.map(sc => {
                                                    return (
                                                        {
                                                            title: sc.category_name,
                                                            value: sc.category_job_id,
                                                        }
                                                    )
                                                })

                                            }

                                            )
                                        })}


                                />

                            </Form.Item>
                        </Col>
                    </Row>


                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <TextArea rows={2} placeholder={'Description...'} />
                    </Form.Item>
                    <Form.Item>
                        Verification Document :
                        <Radio.Group name="radiogroup" defaultValue={'cin'}
                            onChange={(e) => setVerificationDocument(e.target.value)}>
                            <Radio value={'cin'}>CIN</Radio>
                            <Radio value={'passport'}>Passport</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {verificationDocument === 'cin' && <Form.Item
                        name="num_cin"
                        label="Cin"
                    >
                        <NumericInput style={{ width: '100%' }} maxLength={8} placeholder={'CIN ...'} />

                    </Form.Item>}

                    {verificationDocument === 'passport' && <Form.Item
                        name="num_passport"
                        label="Passport"
                    >
                        <NumericInput style={{ width: '100%' }} maxLength={999} placeholder={'Numéro de Passport ...'} />
                    </Form.Item>}


                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="bank_name"
                                label="Bank"
                            >
                                <Input placeholder={'Bank...'} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="bank_agency"
                                label="Agence bancaire"
                            >
                                <Input placeholder={'Agence bancaire ...'} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        name="num_rib"
                        label="Numéro RIB"
                    >
                        <NumericInput style={{ width: '100%' }} maxLength={20} placeholder={'Numéro RIB ...'} />
                    </Form.Item>
                    {selectedCategory && selectedCategory.have_card &&
                        <Form.Item
                            name="num_pro_card"
                        label="Numéro de carte pro"
                        >
                            <Input style={{ width: '100%' }} placeholder={'Numéro de carte pro ...'} />
                        </Form.Item>
                    }
                    <Form.Item
                        name="min_call_allowed_per_sec"
                        label="Numéro de carte pro"
                    >
                        <InputNumber type={'number'} min={0} style={{ width: '100%' }}
                            placeholder={'Durée minimale d\'appel autorisée par seconde'} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" block htmlType="submit">
                            Valider
                        </Button>
                    </Form.Item>

                </Form>

            </Modal>
        </div>
    );
};

export default AddProfessionnel;
