import axios from 'axios';

class TransactionService {
    async get_transations_history(params) {
        console.log("get_transations_history", params);
        return await axios.get(`${process.env.REACT_APP_API_URL}backoffice/fetch_all_transactions`, { params })
            .then(({ data }) => {
                return data.data;
            }).catch(error => {
                return error.response
            });
    }
    async export_transations_csv(params) {
        console.log("parexport_virement_csvas", params);
        return await axios.get(`${process.env.REACT_APP_API_URL}backoffice/export_transactions`, { params })
            .then(({ data }) => {
                return data;
            }).catch(error => {
                return error.response
            });
    }

}
export default new TransactionService();
