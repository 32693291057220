import axios from 'axios';

class CategoryService {
    async get_all_categories() {
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}category_job/fetch_professions`);
        return data

    }

    async create_new_category(category){
        return await axios.post(`${process.env.REACT_APP_API_URL}category_job/create`,category).then((response)=>{
            return response.data.data;
        }).catch(error => {
            return error.response
        })
    }
    async delete_category(id){
        return await axios.delete(`${process.env.REACT_APP_API_URL}category_job/delete/${id}`).then((response)=>{
            return response.data.status;
        }).catch(error => {
            return error.response
        })
    }
    async update_category(id,category){
        return await axios.put(`${process.env.REACT_APP_API_URL}category_job/update/${id}`,category).then((response)=>{
            return response.data.status;
        }).catch(error => {
            return error.response
        })
    }
}
export default new CategoryService();
