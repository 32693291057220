import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import LoginComponent from "./LoginComponent";
const AuthentificationRouter = ({match}) => {
    return (

            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/login`} />
                <Route path={`${match.url}/login`} component={LoginComponent} />
                <Redirect to="/error" />
            </Switch>

    );
};

export default AuthentificationRouter;
