import './App.css';
import 'antd/dist/antd.css';
import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import AuthentificationRouter from "./Components/AuthentificationRouter";
import AdminRouter from "./Components/AdminRouter";

const defaultRoute = () => {
    return (<Redirect to="/admin"/>);
};

const AuthRoute = ({component: Component, ...rest}) => {
    const accessToken = localStorage.getItem('accessToken');
    return (
        <Route
            {...rest}
            render={props =>
                accessToken ? (<Component {...props} />) : (<Redirect to={{pathname: "/authentication/login"}}/>)
            }
        />
    );
};

function App() {

    return (
        <Router>
            <Switch>
                <AuthRoute path="/admin" component={AdminRouter}/>
                <Route path="/authentication" component={AuthentificationRouter}/>
                <Route path="/" exact component={defaultRoute}/>

            </Switch>
        </Router>
    );
}

export default App;
