import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Radio, Row, Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import ProfessionnelService from "../../Services/ProfessionnelService";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const dummyRequest = ({file, onSuccess}, name) => {
    file.filename = name;
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};
const uploadButton = (title) => (
    <div>
        <PlusOutlined/>
        <div style={{marginTop: 8}}>{title}</div>
    </div>
);
const PicturesWall = ({files, mtoken, userId}) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [CINRectoList, setCINRectoList] = useState([]);
    const [CINVersoList, setCINVersoList] = useState([]);
    const [PassList, setPassList] = useState([]);
    const [RIBList, setRIBList] = useState([]);
    const [ProRectoList, setProRectoList] = useState([]);
    const [ProVersoList, setProVersoList] = useState([]);
    const [verificationDocument, setVerificationDocument] = useState(null);

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewVisible(true);
        setPreviewImage(file.url || file.preview);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({fileList}) => setFileList(fileList);

    useEffect(() => {
        setFileList(files)
    }, [files, mtoken, userId]);

    const upload = () => {
        const formData = new FormData();
        const files = CINRectoList.concat(CINVersoList).concat(PassList).concat(RIBList).concat(ProRectoList).concat(ProVersoList);

        formData.append('user_id', userId);
        if (files) {
            files.map(pic =>
                formData.append('file', pic.originFileObj, pic.filename)
            )
        }
        ProfessionnelService.upload_Image(formData);
    };
    return fileList && (

        <div>
            <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
            >

            </Upload>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{width: '100%'}} src={previewImage}/>
            </Modal>
            Verification Documents : <Radio.Group onChange={(e) => setVerificationDocument(e.target.value)}>
            <Radio value={'rib'}>RIB</Radio>
            <Radio value={'cin'}>CIN</Radio>
            <Radio value={'pass'}>Passport</Radio>
            <Radio value={'pro_card'}>Pro Card</Radio>
        </Radio.Group>
            <Row>
                <Col>
                    {verificationDocument === 'cin' && <Upload
                        listType="picture-card"
                        customRequest={(e) => dummyRequest(e, 'CIN_RECT-' + mtoken)}
                        filename={'CIN_RECT-' + mtoken}
                        fileList={CINRectoList}
                        onPreview={handlePreview}
                        onChange={({fileList}) => {
                            setCINRectoList(fileList);
                        }}
                    >
                        {CINRectoList.length > 0 ? null : uploadButton('CIN RECTO')}
                    </Upload>}
                </Col>
                <Col>
                    {verificationDocument === 'cin' && <Upload
                        customRequest={(e) => dummyRequest(e, 'CIN_VERS-' + mtoken)}
                        listType="picture-card"
                        fileList={CINVersoList}
                        onPreview={handlePreview}
                        onChange={({fileList}) => setCINVersoList(fileList)}
                    >
                        {CINVersoList.length > 0 ? null : uploadButton('CIN VERSO')}
                    </Upload>}
                </Col>
            </Row>
            {verificationDocument === 'pass' && <Upload
                customRequest={(e) => dummyRequest(e, 'PASS-' + mtoken)}
                listType="picture-card"
                fileList={PassList}
                onPreview={handlePreview}
                onChange={({fileList}) => setPassList(fileList)}

            >
                {uploadButton('Passport')}
            </Upload>}
            {verificationDocument === 'rib' && <Upload
                customRequest={(e) => dummyRequest(e, 'RIB-' + mtoken)}
                listType="picture-card"
                fileList={RIBList}
                onPreview={handlePreview}
                onChange={({fileList}) => setRIBList(fileList)}
            >
                {uploadButton('RIB')}
            </Upload>}
            <Row>
                <Col>
                    {verificationDocument === 'pro_card' && <Upload
                        customRequest={(e) => dummyRequest(e, 'PRO_RECT-' + mtoken)}
                        listType="picture-card"
                        fileList={ProRectoList}
                        onPreview={handlePreview}
                        onChange={({fileList}) => setProRectoList(fileList)}
                    >
                        {ProRectoList.length > 0 ? null : uploadButton('CARD PRO RECTO')}
                    </Upload>}
                </Col>
                <Col>
                    {verificationDocument === 'pro_card' && <Upload
                        customRequest={(e) => dummyRequest(e, 'PRO_VERS-' + mtoken)}
                        fileList={ProVersoList}
                        listType="picture-card"
                        onPreview={handlePreview}
                        onChange={({fileList}) => setProVersoList(fileList)}
                    >
                        {uploadButton('CARD PRO VERSO')}
                    </Upload>}
                </Col>
            </Row>
            <div>
                {verificationDocument && <Button onClick={upload}>Upload Images</Button>}
            </div>
            <br/>
        </div>
    );
};

export default PicturesWall;
