import React from 'react';
import { Badge, Space, Table, Tooltip, Tag} from "antd";
import Icon from "@ant-design/icons";
import {ReactComponent as UpdateIcon} from "../../icons/refresh.svg";

const ClientTable = ({data,selectClient}) => {
    const generateDate = time =>
        new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            time.split(":")[0],
            time.split(":")[1],
            0
        );
    const res = UTCString => {
        const result = new Date(
            UTCString.setMinutes(UTCString.getMinutes() + (2 * 60))
        );
        return result
    }
    const columns = [
        {
            dataIndex: "active",
            key: "active",
            render: (text, value) => {
                return value.active==='true' ? <Tooltip title="Active">
                    <Badge status="success"/>
                </Tooltip> : <Tooltip title="Blocked">
                    <Badge status="error"/>
                </Tooltip>
            }
        },
        {
            title: "Nom",
            dataIndex: "first_name",
            key: "first_name",
        },
        {
            title: "Prénom",
            dataIndex: "last_name",
            key: "last_name",
        },
        {
            title: "Num Tél",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Dernier Mise à jour",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: updatedAt => {
                let msg
                if (updatedAt) {
                    const Date = updatedAt.split('T')[0]
                    const Time = updatedAt.split('T')[1].split('.')[0].substring(0, 5)
                    const UTCString = generateDate(Time);
                    const readbleString = res(UTCString).toTimeString().split(" ")[0];
                    msg = `le : ${Date} à: ${readbleString.substr(0, readbleString.length - 3)}`
                }
         
                return (
                    <Tag color="blue" key={updatedAt}>
                        {msg}
                    </Tag>
                )
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, value) => {
                return (
                    <Space>
                        <Tooltip title="Mettre à jour">
                            <Icon component={UpdateIcon} onClick={()=>{selectClient(value)}} style={{fontSize: '32px'}}/>
                        </Tooltip>

                    </Space>
                )
            }
        },
    ];
    return data?(
        <div>
            <Table columns={columns} pagination={false} dataSource={data.map((c, index) => {
                return {
                    key: index,
                    first_name: c.first_name || '-',
                    last_name: c.last_name || '-',
                    phone: c.phone || '-',
                    email: c.email || '-',
                    state_account: c.state_account,
                    active: c.active ,
                    region:c.region ,
                    user_id:c.user_id,
                    type:c.type,
                    updatedAt: c.updatedAt,

                }
            })}/>
        </div>
    ):'loading...';
};

export default ClientTable;
