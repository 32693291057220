import React, { useEffect, useState } from 'react';
import ProfessionnelsTable from "../Containers/professionnels/ProfessionnelsTable";
import ProfessionnelService from "../Services/ProfessionnelService";
import { Button, Card, Col, Input, message, Pagination, Radio, Row, Select, TreeSelect } from 'antd';
import ProCallHistory from "../Containers/professionnels/ProCallHistory";
import AddProfessionnel from "../Containers/professionnels/AddProfessionnel";
import CategoryService from "../Services/CategoryService";
import ProVirementHistory from "../Containers/professionnels/ProVirementHistory";

import UpdateProfessionnel from "../Containers/professionnels/UpdateProfessionnel";

const { Option } = Select;
const { Search } = Input;

let params = { type: 'professionel', page: 1, finished_signup: true };
const ProfessionnelComponent = () => {

    // Data Initial
    const [professionnels, setProfessionnels] = useState([]);
    const [totalItems, setTotalItems] = useState(null);
    const [categories, setCategories] = useState([]);
    //Modals
    const [openCallHistory, setOpenCallHistory] = useState(false);
    const [openVirementHistory, setOpenVirementHistory] = useState(false);
    const [openProModal, setOpenProModal] = useState(false);
    const [openUpdateProModal, setOpenUpdateProModal] = useState(false);
    //Data Linked to selected Pro
    const [selectedProfessionnel, setSelectedProfessionnel] = useState(null);
    const [callHistory, setCallHistory] = useState([]);
    const [virementHistory, setVirementHistory] = useState([]);
    //Filter Fields
    const [filterOption, setFilterOption] = useState(null);
    const [searchField, setSearchField] = useState('');
    const [categoryField, setCategoryField] = useState(null);
    const [accountStatusField, setAccountStatusField] = useState('all');
    const [accountPaymentField, SetAccountPaymentField] = useState('all');

    const fetch_categories = () => {
        CategoryService.get_all_categories().then(({ data }) => setCategories(data))
    };
    const fetch_professionnels = () => {
        ProfessionnelService.get_all_professionnels(params).then((response) => {
            setProfessionnels(response.professionnels);
            setTotalItems(response.countTotal);
        }).catch((e) => console.log(e.message))
    };
    const handlesearch = () => {
        params.page = 1;
        fetch_professionnels();
    };
    const handleOpenCallHistory = () => {
        setOpenCallHistory(!openCallHistory);
    };
    const handleOpenVirementHistory = () => {
        setOpenVirementHistory(!openVirementHistory);
    };
    const handleOpenAddProModal = () => {
        setOpenProModal(!openProModal);
    };
    const handleOpenUpdateProModal = () => {
        setOpenUpdateProModal(!openUpdateProModal);
    };


    const handleCallHistory = (e) => {
        setOpenCallHistory(!openCallHistory);
        ProfessionnelService.get_professionnel_call_history(e.userId).then(({ callHistory }) => {
            setCallHistory(callHistory)
        })
    };
    const handleVirementHistory = (e) => {
        setOpenVirementHistory(!openVirementHistory);
        ProfessionnelService.fetch_professionnel_transfers(e.userId).then(({ fetch_tranfers }) => {
            setVirementHistory(fetch_tranfers)
        })
    };

    const handleUpdateSelectedPro = (pro_id, values) => {
        ProfessionnelService.update_professionnel(pro_id, values)
            .then(() => message.success('professionnel mis à jour avec succès ')).then(() => fetch_professionnels())
            .catch(() => message.warn('l\'opération mise à jour a échoué '));
    };

    const handleUpdate = (e) => {
        setSelectedProfessionnel(e);
        setOpenUpdateProModal(!openUpdateProModal);
    };

    const handleAddPro = (e) => {
        ProfessionnelService.create_professionnel(e).then((response) => fetch_professionnels()).then(() =>
            message.success('professionnel créé avec succès')).catch(() => message.warn('l\'opération de création a echoué'))
    };

    const handleStatusAccount = (e) => {
        setAccountStatusField(e);
        delete params.state_account;
        delete params.page;
        if (e !== 'all')
            params.state_account = e;

        fetch_professionnels();
    };
    const handleStatusPayment = (e) => {
        SetAccountPaymentField(e);
        delete params.state_payment_signup;
        delete params.page;
        if (e !== 'all')
            params.state_payment_signup = e;

        fetch_professionnels();
    };
    const handleClear = () => {
        params = { type: 'professionel', page: 1 };
        delete params.category_job_id;
        setSearchField('');
        setCategoryField(null);
        setAccountStatusField('all')
        SetAccountPaymentField('all')
        fetch_professionnels();
    };

    useEffect(() => {
        params = { type: 'professionel', page: 1, finished_signup: true };
        fetch_categories();
        fetch_professionnels();
    }, []);
    return professionnels ? (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card title={'Filtre'}>
                        <Input.Group compact>
                            <Select defaultValue={null} onSelect={(e) => {
                                setFilterOption(e)
                            }}>
                                <Option value={null}>Options</Option>
                                <Option value="Category">Catégories</Option>
                            </Select>
                            {filterOption === null ?
                                <Search style={{ width: '30%' }} placeholder="Rechercher"
                                    value={searchField}
                                    onChange={(e) => {
                                        setSearchField(e.target.value);
                                        e.target.value !== '' ? params.search = e.target.value : delete params.search
                                    }}
                                    onSearch={(e) => {
                                        handlesearch()
                                    }} enterButton /> :
                                <TreeSelect
                                    placeholder="Catégorie..."
                                    onSelect={(e, v) => {
                                        params.category_job_id = e;
                                        delete params.page;
                                        fetch_professionnels();
                                        setCategoryField(v.title);

                                    }}
                                    value={categoryField}
                                    onClear={() => {
                                        delete params.category_job_id;
                                        fetch_professionnels()
                                    }}
                                    style={{ width: '30%' }}
                                    treeData={
                                        categories && categories.map((c, index) => {
                                            return ({
                                                title: c.category_name,
                                                value: c.category_job_id,
                                                children: c.speciality.length > 0 && c.speciality.map(sc => {
                                                    return (
                                                        {
                                                            title: sc.category_name,
                                                            value: sc.category_job_id,
                                                        }
                                                    )
                                                })

                                            }

                                            )
                                        })}
                                />

                            }
                        </Input.Group>
                        <br />
                        Statut :  <Radio.Group defaultValue={'all'} value={accountStatusField}
                            onChange={(e) => {
                                handleStatusAccount(e.target.value)
                            }}>
                            <Radio value={'all'}>Tous</Radio>
                            <Radio value={'approuved'}>Approuvé</Radio>
                            <Radio value={'not-approuved'}>Rejeté</Radio>
                            <Radio value={null}>En attente</Radio>
                        </Radio.Group>
                        <br />
                        Statut_payment :  <Radio.Group defaultValue={'all'} value={accountPaymentField}
                            onChange={(e) => {
                                handleStatusPayment(e.target.value)
                            }}>
                            <Radio value={'all'}>Tous</Radio>
                            <Radio value={'null'}>Non payé</Radio>
                            <Radio value={'payed'}>Payé</Radio>
                        </Radio.Group>
                        <Row justify={'end'}>
                            <Button onClick={() => {
                                handleClear()
                            }}>Réinitialiser filtre</Button>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row justify={'end'} gutter={[0, 16]}>
                <Button type={'primary'} onClick={() => handleOpenAddProModal()}>Ajouter professionnel</Button>
            </Row>
            <br />
            <ProfessionnelsTable data={professionnels}
                update={handleUpdate}
                call={handleCallHistory}
                virement={handleVirementHistory} />
            <Row style={{ paddingTop: 10 }} justify={'end'}>
                <Pagination defaultCurrent={1}
                    total={totalItems}
                    current={params.page || 1}
                    showSizeChanger
                    pageSizeOptions={[10, 20, 50, 100]}
                    onShowSizeChange={(e, v) => {
                        params.limit = v;
                        fetch_professionnels()
                    }}
                    onChange={(e, v) => {
                        params.page = e;
                        fetch_professionnels()
                    }} />
            </Row>

            <ProCallHistory data={callHistory} visible={openCallHistory} openModal={handleOpenCallHistory} />
            <ProVirementHistory data={virementHistory} visible={openVirementHistory}
                openModal={handleOpenVirementHistory} />
            <AddProfessionnel visible={openProModal} openModal={handleOpenAddProModal} addPro={handleAddPro} />
            {selectedProfessionnel &&
                <UpdateProfessionnel visible={openUpdateProModal} openModal={handleOpenUpdateProModal}
                    pro={selectedProfessionnel} updatePro={handleUpdateSelectedPro} />}
        </div>
    ) : 'loading';
};

export default ProfessionnelComponent;
