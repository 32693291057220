import React from 'react';
import {Modal, Table} from "antd";
const columns = [

    {
        title: "Date",
        dataIndex: "transfer_date",
        key: "transfer_date",
    },
    {
        title: "montant",
        dataIndex: "ammount",
        key: "ammount",
    },
    {
        title: "Statut",
        dataIndex: "status",
        key: "status",
    }
];
const ProVirementHistory = ({data,visible,openModal}) => {
    return (
        <div>
            <Modal
                title="Historique des virements"
                centered
                visible={visible}
                onCancel={openModal}
                footer={[null]}
                width={1000}
                height={500}
            >
                {data&&<Table columns={columns} dataSource={data.map((c, index) => {
                    return {
                        key: index,
                        transfer_date: c.transfer_date || '-',
                        status: c.status,
                        ammount: c.ammount|| '-'
                    }})}/>}
            </Modal>
        </div>
    );
};

export default ProVirementHistory;
