import axios from 'axios';

class StatService {
    async get_stat_info(){
        return await axios.get(`${process.env.REACT_APP_API_URL}backoffice/fetch_stats`)
            .then(({data}) => {
                return data.data;
            }).catch(error => {
                return error.response
            });
    }


}
export default new StatService();
