import React from 'react';
import {Button, Col, Form, Input, InputNumber, Modal, Row, Switch} from "antd";

const {TextArea} = Input;

const AddSubCategory = ({openModal, visible, AddSubCategory}) => {
    const [form] = Form.useForm();


    const onFinish = (values) => {
        values.active = true;
        AddSubCategory(values);
        form.resetFields();
        openModal();
    };

    const onFinishFailed = (errorInfo) => {
    };
    return (
        <div>
            <Modal
                visible={visible}
                title="Ajouter une sous catégorie"
                onCancel={() => {
                    openModal();
                    form.resetFields();
                }}
                footer={null}
            >
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="category_name"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer le titre de catégorie!',
                            },
                        ]}
                    >
                        <Input placeholder={'Titre'}/>
                    </Form.Item>

                    <Form.Item
                        name="category_description"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer la description!',
                            },
                        ]}
                    >
                        <TextArea rows={2} placeholder={'Description...'}/>
                    </Form.Item>

                    <Form.Item name="have_card" label={'Carte Pro'}>
                        <Switch checkedChildren="have card" unCheckedChildren="No Card"
                                defaultChecked={false}/>
                    </Form.Item>

                    <Form.Item
                        name="annuel_sub_price"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer le prix d\'inscription annuel!',
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder={'Prix d\'inscription annuel /TND'}
                            style={{
                                width: '100%',
                            }}
                            step={'0.1'}
                            type={'number'}
                            min="0"
                        />
                    </Form.Item>
                    <Form.Item
                        name="price_per_sec"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer le prix d\'appel par seconde!',
                            },
                        ]}
                    >
                        <Input
                            placeHolder={'Prix d\'appel par seconde /TND'}
                            style={{
                                width: '100%',
                            }}
                            type={'number'}
                            step={0.001}
                            min="0"
                        />
                    </Form.Item>
                    <Form.Item
                        name="istishara_commision"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer la commission de ISTISHARA !',
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder={'ISTISHARA commission %'}
                            style={{
                                width: '100%',
                            }}
                            step={'0.1'}
                            type={'number'}
                            min="0"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" block htmlType="submit">
                            Valider
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AddSubCategory;
