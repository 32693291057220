import React, {useEffect,useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Modal, Row, Switch} from "antd";

const {TextArea} = Input;

const UpdateCategory = ({openModal, visible, category,update}) => {
    const [form] = Form.useForm();
    const [has_speciality,sethas_speciality] = useState(null);
    const [have_card,sethave_card] = useState(null);
    const onFinish = (values) => {
        values.active = true;
        update(values)
    };
    useEffect(() => {
        form.setFieldsValue(category);
        sethas_speciality(category.has_speciality||false);
        sethave_card(category.have_card||false);
    }, [category,form]);
    const onFinishFailed = (errorInfo) => {
    };
    return (
        <div>
            <Modal
                visible={visible}
                title="Mise à jour de catégorie"
                onCancel={openModal}
                footer={null}
            >
                <Form
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="category_name"
                        label="Nom"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer le titre de catégorie!',
                            },
                        ]}
                    >
                        <Input placeHolder={'Titre'} defaultValue={category.category_name}/>
                    </Form.Item>

                    <Form.Item
                        name="category_description"
                        label="Description"
                        rules={[
                            {
                                required: true,
                                message:'Veuillez insérer la description!',
                            },
                        ]}
                    >
                        <TextArea rows={2} placeHolder={'Description...'} defaultValue={category.category_description}/>
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            {!category.parent_category_job_id&&<Form.Item name="has_speciality" label={'Sous catégorie'}>
                                <Switch checkedChildren="has speciality" unCheckedChildren="No speciality"
                                        onChange={(e)=>sethas_speciality(e)}
                                        checked={has_speciality}
                                />
                            </Form.Item>}
                        </Col>
                        <Col span={!category.parent_category_job_id?12:24}>
                            <Form.Item name="have_card" label={'Carte Pro'}>
                                <Switch checkedChildren="have card" unCheckedChildren="No Card"
                                        onChange={(e)=>sethave_card(e)}
                                        checked={have_card}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        name="annuel_sub_price"
                        label="Prix d'inscription annuel TND"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer le prix d\'inscription annuel!',
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder={'Prix d\'inscription annuel /TND'}
                            style={{
                                width: '100%',
                            }}
                            min="0"
                            type={'number'}
                            step={'0.1'}
                            defaultValue={category.annuel_sub_price}
                        />
                    </Form.Item>
                    <Form.Item
                        name="price_per_sec"
                        label="Prix d'appel par seconde TND"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer le prix d\'appel par seconde!',
                            },
                        ]}
                    >
                        <Input
                            placeHolder={'Prix d\'appel par seconde /TND'}
                            style={{
                                width: '100%',
                            }}
                            type={'number'}
                            step={0.001}
                            min="0"
                            defaultValue={category.price_per_sec}
                        />
                    </Form.Item>
                    <Form.Item
                        name="istishara_commision"
                        label="ISTISHARA commission"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer la commission de ISTISHARA !',
                            },
                        ]}
                    >
                        <Input
                            placeHolder={'ISTISHARA commission %'}
                            style={{
                                width: '100%',
                            }}
                            step={'0.1'}
                            min="0"
                            type={'number'}
                            defaultValue={parseFloat(category.istishara_commision)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" block htmlType="submit">
                            Valider
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default UpdateCategory;
