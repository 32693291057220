import React from 'react';
import { Button, Form, Input, InputNumber, Row, Col, Modal, Select, Switch } from "antd";
import countries from '../../Constants/countries'
import { findPhoneNumbersInText } from 'libphonenumber-js'
import NumericInput from "../../Helpers/NumericInput";
const { Option } = Select;


function validatePhoneNumber(code, number, cb) {

    let phoneNumber = code + number;
    let phone = phoneNumber && findPhoneNumbersInText(phoneNumber);
    if (!phone[0])
        cb('Numéro de téléphone est invalide !');

    cb()

}

const AddClient = ({ openModal, visible, addclient }) => {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        values.type = 'client';
        values.phone = values.phonecode + values.phone;
        delete values.phonecode;
        values.active = values.active.toString();
        addclient(values);
        form.resetFields();
        openModal();
    };

    const onFinishFailed = (errorInfo) => {
    };
    return (
        <div>
            <Modal
                visible={visible}
                title="Ajouter un client"
                onCancel={() => { openModal(); form.resetFields(); }}
                footer={null}
            >
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ phonecode: '+216', active: 'false' }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Nom"
                        name="first_name"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer votre nom!',
                            },
                        ]}
                    >
                        <Input placeholder={'Nom'} />
                    </Form.Item>
                    <Form.Item
                        label="Prénom"
                        name="last_name"
                        rules={[
                            {
                                required: true,
                                message: 'Veuillez insérer votre prénom!',
                            },
                        ]}
                    >
                        <Input placeholder={'Prénom'} />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                    >
                        <Input placeholder={'Email..'} />
                    </Form.Item>
                    <Form.Item name="active" label={'statut : '}>
                        <Switch checkedChildren="Active" unCheckedChildren="Blocked" defaultChecked={false} />
                    </Form.Item>
                    <Form.Item
                    ><Input.Group >
                            <Row  >
                                <Col >
                                    <Form.Item

                                        label="Téléhpone"
                                        name={'phonecode'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Veuillez choisir le code de votre pays !',
                                            },
                                        ]}
                                    >
                                        <Select showSearch defaultValue={'+216'} style={{ width: 100 }}>
                                            {countries.map((x, index) => {
                                                return (<Option key={index} value={x.dial_code}>{x.dial_code}</Option>)
                                            })}

                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col >
                                    <Form.Item
                                        name={'phone'}
                                        noStyle
                                        rules={[{ required: true, message: 'Numéro de téléphone est obligatoire' },
                                        {
                                            validator: (rule, value, cb) => {
                                                validatePhoneNumber(form.getFieldValue('phonecode'), value, cb)
                                            }
                                        }
                                        ]}
                                    >
                                        <NumericInput maxLength={8} placeholder={'Téléphone ...'} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Input.Group>

                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" block htmlType="submit">
                            Valider
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AddClient;
