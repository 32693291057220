import React, {useEffect, useState} from 'react';
import TableTreeCategory from "../Containers/category/TableTreeCategory";
import CategoryService from "../Services/CategoryService";
import {Button, message, Modal, Row} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import AddCategory from "../Containers/category/AddCategory";
import UpdateCategory from "../Containers/category/UpdateCategory";
import AddSubCategory from "../Containers/category/AddSubCategory";


const {confirm} = Modal;

function showConfirm(category, fetch_categories) {
    return confirm({
        title: 'Voulez-vous supprimer cette catégorie?',
        icon: <ExclamationCircleOutlined/>,
        content: <ul>
            <li>{category.category_name}</li>
        </ul>,
        onOk() {
            CategoryService.delete_category(category.key).then(() => fetch_categories());
            message.success('Catégorie supprimée avec succès')
        },
        onCancel() {

        },
    });
}

const CategoriesComponent = () => {

    const [categories, setCategories] = useState(null);
    const [addCatModal, setAddCatModal] = useState(false);
    const [addSubCatModal, setAddSubCatModal] = useState(false);
    const [updateCatModal, setUpdateCatModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleUpdateModal = (e) => {
        setUpdateCatModal(!updateCatModal);
        setSelectedCategory(e);
        if (!updateCatModal === false)
            setSelectedCategory(null)
    };

    const handleOpenAddModal = (e) => {
        setAddCatModal(!addCatModal);
    };
    const handleOpenAddSubModal = (e) => {
        setSelectedCategory(e);
        setAddSubCatModal(!addSubCatModal);
        if (!addSubCatModal === false)
            setSelectedCategory(null)
    };
    const handleConfirmDelete = (e) => {
        showConfirm(e, fetch_categories);
    };

    const handleAddSubCategory = (e) => {
        if (selectedCategory)
            e.parent_category_job_id = selectedCategory.key;

        CategoryService.create_new_category(e)
            .then(r => message.success('Sous catégorie créée avec succès')).then(() =>{setSelectedCategory(null); fetch_categories()})
            .catch(e => message.error('l\'opération de création  a echoué'))
    };
    const handleAddCategory = (e) => {
        CategoryService.create_new_category(e)
            .then(r => message.success('Catégorie créée avec succès')).then(() => fetch_categories())
            .catch(e => message.error('l\'opération de création  a echoué'))
    };


    const handleUpdateCategory = (e) => {
        CategoryService.update_category(selectedCategory.key, e)
            .then(() => message.success('Catégorie mise à jour avec succès'))
            .then(() =>{ fetch_categories();setSelectedCategory(null);}).catch(e => message.error('l\'opération mise à jour a echoué '))
    };


    const fetch_categories = () => {
        CategoryService.get_all_categories().then(({data}) => {
            return data.map((x, index) => x.has_speciality && x.speciality.length > 0 ? {
                key: x.category_job_id,
                category_name: x.category_name,
                category_description: x.category_description,
                has_speciality: x.has_speciality,
                annuel_sub_price: x.annuel_sub_price,
                have_card: x.have_card,
                price_per_sec: x.price_per_sec,
                istishara_commision: x.istishara_commision,
                parent_category_job_id: x.parent_category_job_id,
                children: x.speciality.map((s, index) => ({
                    key: s.category_job_id,
                    category_name: s.category_name,
                    have_card: s.have_card,
                    category_description: s.category_description,
                    annuel_sub_price: s.annuel_sub_price,
                    price_per_sec: s.price_per_sec,
                    istishara_commision: s.istishara_commision,
                    parent_category_job_id: s.parent_category_job_id
                }))
            } : {
                key: x.category_job_id,
                category_name: x.category_name,
                category_description: x.category_description,
                has_speciality: x.has_speciality,
                annuel_sub_price: x.annuel_sub_price,
                price_per_sec: x.price_per_sec,
                istishara_commision: x.istishara_commision,
                have_card: x.have_card,
                parent_category_job_id: x.parent_category_job_id
            })
        }).then((data) => {
            setCategories(data);
            setSelectedCategory(null)
        }).catch((e) => console.log(e.message))
    };

    useEffect(() => {
        fetch_categories();
    }, []);

    return (
        <div>
            <Row gutter={[0, 16]} width={"100%"} justify={'end'}>
                <Button type={'primary'} onClick={handleOpenAddModal}>
                    Ajouter une catégorie
                </Button>
            </Row>
            <br/>
            <div>
                <TableTreeCategory data={categories} openUpdateModal={handleUpdateModal}
                                   openAddSubModal={handleOpenAddSubModal} openDeleteModal={handleConfirmDelete}/>
            </div>
            <AddCategory openModal={handleOpenAddModal} visible={addCatModal} AddCategory={handleAddCategory}/>
            <AddSubCategory openModal={handleOpenAddSubModal} visible={addSubCatModal} AddSubCategory={handleAddSubCategory}/>
            {selectedCategory && !addSubCatModal&&
            <UpdateCategory category={selectedCategory} update={handleUpdateCategory} visible={updateCatModal}
                            openModal={handleUpdateModal}/>}

        </div>
    );
};

export default CategoriesComponent;
