import React,{useState,useEffect} from 'react';
import {Layout, Menu} from "antd";
import {PhoneOutlined, PieChartOutlined, SwapOutlined, UserOutlined, WindowsOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import '../assets/styles/istishara.css'

const {SubMenu} = Menu;
const {Header, Content, Sider, Footer} = Layout;
const AdminLayout = ({children}) => {
    const [activeKey, setActiveKey]= useState('1');

        useEffect(()=>{
            const page =window.location.pathname;


            if (page==='/admin/dashboard')
                setActiveKey('1');
            if (page==='/admin/categories')
                setActiveKey('2');
            if (page==='/admin/professionnels')
                setActiveKey('3');
            if (page==='/admin/clients')
                setActiveKey('4');
            if (page==='/admin/CallHistory')
                setActiveKey('5');
            if (page==='/admin/TransactionsHistory')
                setActiveKey('6');


    });
    return (
        <div>
            <Layout>
                <Header className="header ">
                    <Link to={"/admin"}>
                        <div className={'applogo'}><p>Istishara</p></div>
                    </Link>
                    <Menu className={'navBar'} theme={'dark'} mode="horizontal">


                        <SubMenu key="sub1" icon={<UserOutlined/>} title="Administrateur">
                            <Menu.Item key="1" onClick={() => {
                                localStorage.removeItem('accessToken');
                                window.location.reload()
                            }}>Déconnexion</Menu.Item>
                        </SubMenu>
                    </Menu>


                </Header>
                <Layout>
                    <Sider width={200} className="site-layout-background">
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            selectedKeys={[activeKey]}

                            style={{height: '100%', borderRight: 0}}
                        >
                            <Menu.Item key="1" icon={<PieChartOutlined/>}>
                                <Link to={"/admin/dashboard"}>Dashboard</Link>
                            </Menu.Item>

                            <Menu.Item key="2" icon={<WindowsOutlined/>}>
                                <Link to={"/admin/categories"}>
                                    Catégories
                                </Link>
                            </Menu.Item>


                            <Menu.Item key="3" icon={<UserOutlined/>}>
                                <Link to={"/admin/professionnels"}>
                                    Professionnels
                                </Link>
                            </Menu.Item>


                            <Menu.Item key="4" icon={<UserOutlined/>}>
                                <Link to={"/admin/clients"}>
                                    Clients
                                </Link>
                            </Menu.Item>


                            <Menu.Item key="5" icon={<PhoneOutlined/>}>
                                <Link to={"/admin/CallHistory"}>
                                    Appels
                                </Link>
                            </Menu.Item>


                            <Menu.Item key="6" icon={<SwapOutlined/>}>
                                <Link to={"/admin/TransactionsHistory"}>
                                    Transactions
                                </Link>
                            </Menu.Item>

                        </Menu>
                    </Sider>
                    <Layout style={{padding: '0 24px 24px'}}>

                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: '100vh',
                            }}
                        >
                            <div className="site-layout-content">
                                <main>
                                    {children}
                                </main>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
                <Footer style={{textAlign: 'center'}}>Istishara Back_office ©2021 Created by DIGIT-U</Footer>
            </Layout>
        </div>
    );
};

export default AdminLayout;
