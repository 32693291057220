import axios from 'axios';

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        config.headers.authorization = token;

        return config;
    },
    error => {
        return Promise.reject(error);
    },
);


